@tailwind base;

@layer base {
  :root {
    --color-white: theme(colors.white);

    --color-primary: theme(colors.primary.500); /** Primary color */
    --color-primary-hover: theme(colors.primary.600);

    --color-accent: theme(colors.neutral.500);
    --color-accent-hover: theme(colors.neutral.600);

    --color-error: theme(colors.red.500);
    --color-error-hover: theme(colors.red.600);

    --color-warn: theme(colors.amber.300);
    --color-running: theme(colors.blue.500);
    --color-default: theme(colors.neutral.500);

    --color-text: theme(colors.neutral.800);
    --color-backdrop: theme(colors.neutral.500);

    --color-background: theme(colors.white);
    --color-background-light: theme(colors.white);
    --color-background-lighter: theme(colors.neutral.100);
    --color-background-hover: theme(colors.neutral.100);

    --color-border: theme(colors.dune.100);
    --color-border-strong: theme(colors.neutral.400);
    --color-particle: theme(colors.black);
    --color-elements: theme(colors.neutral.200);

    color: theme('colors.neutral.800');
  }

  .dark {
    --color-background: theme(colors.neutral.950);
    --color-background-light: theme(colors.neutral.900);
    --color-background-lighter: theme(colors.neutral.800);
    --color-background-hover: theme(colors.neutral.800);

    --color-backdrop: theme(colors.neutral.500);

    --color-border: theme(colors.dune.800);
    --color-border-strong: theme(colors.neutral.600);
    --color-text: theme(colors.neutral.300);
    --color-particle: theme(colors.white);
    --color-elements: theme(colors.neutral.700);

    color: theme('colors.neutral.300');
  }
}
