@tailwind components;

@layer components {
  /**
   * LAYOUT
   *
   * Default layout styles
   */
  .bg--default {
    @apply bg-neutral-50 dark:bg-neutral-950;
  }

  .bg--contrast-lightest {
    @apply bg-neutral-100 dark:bg-neutral-900;
  }

  .bg--contrast-light {
    @apply bg-neutral-300 dark:bg-neutral-700;
  }

  .border--default {
    border-color: var(--color-border);
  }

  .container--scroll {
    @apply h-full w-full overflow-auto;
  }

  .disabled {
    @apply disabled:cursor-not-allowed disabled:opacity-50;
  }

  .flow {
    background-color: var(--color-background);
    flex-grow: 1;
  }

  .flow__add-row {
    @apply flex h-20 items-center justify-center;
  }

  .flow__row {
    @apply flex h-20 w-64 items-center justify-center md:w-72 lg:w-80;
  }

  .flow__grid {
    display: grid;
    grid-gap: 16px 64px;
    padding: 64px 32px 0 32px;
    grid-template-columns: repeat(11, 1fr);
    width: max-content;
    overflow: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .flow__svg-container {
    @apply pointer-events-none absolute inset-0 h-full w-full;
  }

  .flow__line {
    @apply stroke-zinc-400 dark:stroke-zinc-600;
  }

  .flow-element {
    @apply absolute left-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center border bg-neutral-50 dark:bg-neutral-900;
  }

  .flow-element--ring {
    @apply ring-4 ring-transparent;
  }

  .input__suffix {
    @apply flex size-10 cursor-pointer items-center justify-center text-xl transition duration-75 text-dune-500 dark:text-dune-500 hover:text-dune-800 dark:hover:text-dune-200;
  }

  .input__suffix--hover {
    @apply group-hover:text-dune-800 dark:group-hover:text-dune-200;
  }

  /**
   * TEXT & TYPOGRAPHY
   *
   * Default text styles
   */
  .text--default {
    @apply text-neutral-700 dark:text-neutral-300;
  }

  .text--contrast-lighter,
  .text--contrast-light {
    @apply text-neutral-900 dark:text-neutral-200;
  }

  .text--contrast-lightest {
    @apply text-neutral-950 dark:text-neutral-100;
  }

  .text--backdrop {
    color: var(--color-backdrop);
  }

  .text--hover-highlight {
    @apply hover:text-neutral-950 dark:hover:text-neutral-50;
  }

  .heading-headline {
    @apply text--contrast-lightest text-4xl font-bold leading-tight;
  }

  .heading-headline--small {
    @apply text--contrast-lightest mb-3 text-3xl font-semibold leading-tight;
  }

  .heading-title {
    @apply text--contrast-lighter mb-4 text-2xl font-semibold;
  }

  .heading-title--small {
    @apply text--contrast-lighter mb-4 text-2xl font-medium;
  }

  .heading-subtitle {
    @apply text--contrast-light mb-3 text-xl font-semibold;
  }

  .heading-subtitle--small {
    @apply text--contrast-light mb-3 text-xl font-medium;
  }

  .heading-section {
    @apply text--contrast-light mb-3 text-lg font-semibold;
  }

  .heading-section--small {
    @apply text--contrast-light mb-3 text-lg font-medium;
  }

  fa-icon {
    @apply flex items-center justify-center;
  }

  mat-icon,
  fa-icon {
    @apply text-base text-dune-800 dark:text-dune-500;
  }

  /**
   * BADGES
   *
   * Default badge styles
   */
  .badge {
    @apply inline-flex items-center gap-x-2 rounded px-2 py-1 text-xs font-medium ring-1 ring-inset;
  }

  .badge--primary {
    @apply bg-primary-50 text-primary-600 ring-primary-500/50 dark:bg-primary-500/20;
  }

  .badge--warn {
    @apply bg-yellow-50 text-yellow-600 ring-yellow-500/50 dark:bg-yellow-500/20;
  }

  .badge--error {
    @apply bg-red-50 text-red-600 ring-red-500/50 dark:bg-red-500/20;
  }

  .badge--info {
    @apply bg-blue-50 text-blue-600 ring-blue-500/50 dark:bg-blue-500/20;
  }

  .badge--neutral {
    @apply bg-neutral-50 text-neutral-600 ring-neutral-500/50 dark:bg-neutral-500/20;
  }

  .stat__title {
    @apply text-neutral-500;
  }

  .stat__value {
    @apply text-xs;
  }

  .stat__link {
    @apply flex items-center gap-x-1 hover:text-primary-500;
  }

  .sidebar__content {
    @apply h-full w-full;
  }

  .sidebar__content--scrollable {
    @apply overflow-y-scroll overflow-x-hidden;
    scrollbar-width: none;
  }

  .highlight {
    @apply mx-0.5 bg-white font-medium text-black;
  }

  .field__info-badge {
    @apply absolute -top-5 right-1;
  }

  .transform--rotate {
    @apply transition-transform duration-200 ease-in-out;
  }

  /**
  * make text for f.e. buttons not highlightable
  */
  .not-highlightable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
