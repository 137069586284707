@tailwind base;

@layer base {
  :root {
    --font-flowzilla: 'Poppins';

    --nav-height: 56px;
    --tabs-height: 59px;
    --detail-height: calc(100vh - calc(var(--nav-height) + var(--tabs-height)));
    --content-height: calc(100vh - var(--nav-height));

    --scrollbar-bg: theme(colors.neutral.50);
    --scrollbar-thumb-bg: theme(colors.greenish.100);
    --scrollbar-thumb-hover-bg: theme(colors.greenish.300);

    --table-header-bg: theme(colors.dune.100);

    --box-shadow-around: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    --box-shadow-glow-primary: 0 0 0.5rem 0 var(--color-primary),
    0 0 2rem 0rem var(--color-primary);

    --gradient-radial-primary: radial-gradient(
        58% 52.5% at 50% 50%,
        rgba(91, 225, 176, 0.26) 0%,
        rgba(177, 56, 94, 0) 100%
    );
  }

  .dark {
    --scrollbar-bg: theme(colors.neutral.950);
    --scrollbar-thumb-bg: theme(colors.greenish.800);
    --scrollbar-thumb-hover-bg: theme(colors.greenish.700);

    --table-header-bg: theme(colors.dune.800);
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-bg);
  }

  .dark ::-webkit-scrollbar-track {
    background: var(--scrollbar-bg);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-bg);
    border-radius: 2px;
  }

  .dark ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-bg);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-bg);
  }

  .dark ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-bg);
  }

  .element {
    scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);
    scrollbar-width: thin;
  }

  .dark .element {
    scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);
  }

  li {
    @apply font-normal;
  }

  button,
  a {
    @apply focus:outline-none;
  }

  svg {
    @apply fill-current;
  }

  th {
    border: none !important;
    background: var(--table-header-bg) !important;
    font-weight: 500 !important;
  }

  th:last-child {
    border-radius: 0 0.5rem 0.5rem 0 !important;
  }

  th:first-child {
    border-radius:  0.5rem 0 0 0.5rem  !important;
  }

  input:autofill {
    @apply bg-white dark:bg-neutral-950;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: var(--color-background);
    -webkit-text-fill-color: var(--color-text);
    transition: background-color 1s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--color-background);
  }

  form {
    @apply py-4;
  }

  .h-calc {
    height: calc(100% - var(--header-height));
  }
}

@keyframes will-o-wisp {
  0%,
  100% {
    transform: translate(0, 0);
    animation-timing-function: ease;
  }
  20% {
    transform: translate(-1.5rem, 0.75rem);
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: translate(0.75rem, -0.375rem);
    animation-timing-function: ease-in-out;
  }
  60% {
    transform: translate(-0.375rem, 0rem);
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: translate(0.375rem, 0.375rem);
    animation-timing-function: ease-in-out;
  }
}

@keyframes flicker {
  0%,
  100% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.2;
  }
}
