:root {
  --mdc-outlined-text-field-outline-color: var(
    --color-border-strong
  ) !important;
  --mdc-checkbox-selected-checkmark-color: white !important;
  --mat-snack-bar-button-color: white !important;
  --mdc-dialog-container-shape: theme('borderRadius.lg');

  /**
   * TOOLTIP
   */
  --mdc-plain-tooltip-supporting-text-size: theme('fontSize.sm');

  /**
   * TABLE
   */
  --mat-table-header-headline-font: theme('fontFamily.flowzilla');
  --mat-table-row-item-label-text-font: theme('fontFamily.flowzilla');

  /**
  * FORM FIELD
  **/
  --mat-form-field-container-height: 28px;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-container-text-font: theme('fontFamily.flowzilla');
  --mat-form-field-subscript-text-size: theme('fontSize.xs');

  /**
  * DIALOG
  **/
  --mdc-dialog-supporting-text-font: theme('fontFamily.flowzilla');
}

.dark {
  --mdc-outlined-text-field-outline-color: var(
    --color-border-strong
  ) !important;
  --mat-autocomplete-background-color: var(--color-background-light) !important;
  --mat-stepper-container-color: var(--color-background);
  --mat-tree-container-background-color: var(--color-background);
  --mat-menu-container-color: var(--color-background-lighter);
  --mdc-dialog-container-color: var(--color-background) !important;
}

.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--color-background) !important;
}

.mat-drawer-container,
.mat-drawer {
  @apply bg-transparent dark:bg-black;
}

.mat-drawer-content {
  @apply bg-white dark:bg-black;
}

.cdk-drop-list .cdk-drag-placeholder {
  @apply !opacity-50;
}

.cdk-overlay-dark-backdrop {
  @apply bg-black/75 dark:bg-white/25;
}

.flow .cdk-drop-list .cdk-drag-placeholder {
  @apply !hidden;
}

.mdc-text-field,
.mdc-text-field--filled {
  @apply bg-white dark:bg-neutral-950;
}

.mdc-notched-outline__notch {
  @apply border-none;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 300ms ease-in-out;
}

/**
 * FORM FIELD
 */
.mat-mdc-form-field-focus-overlay {
  @apply bg-white dark:bg-neutral-950;
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
  @apply !pr-2 pl-1;
}

/**
 * TABLE
 */
.mat-mdc-table {
  @apply dark:bg-black;
}

.mat-mdc-row:hover {
  @apply cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-900;
}
